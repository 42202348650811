









import { Component, Vue } from "vue-property-decorator";

import { API_LOADING_PLACEHOLDER } from "@/api/structs";
import { Meta } from "@/component-decorators";
import { Api } from "@/constants";
import EventDetail from "@/modules/event/detail/components/EventDetail.vue";
import { GOING_CLICKED } from "@/modules/event/event-handlers";
import { eventsStore } from "@/store";

@Component({
  components: { EventDetail },
})
export default class EventDetailContainer extends Vue {
  @Meta
  info() {
    return {
      title: this.event?.name || "",
      meta: [{ name: "description", content: this.event?.summary || "" }],
    };
  }

  get handlerGoingClicked() {
    return GOING_CLICKED;
  }

  get apiLoadingEvent() {
    return eventsStore.loading(Api.EventsLoadEvent);
  }

  get apiLoadingEventGoing() {
    return this.event
      ? eventsStore.loading(`${Api.EventsUpdateEventGoing}--${this.event.id}`)
      : API_LOADING_PLACEHOLDER;
  }

  get event() {
    return eventsStore.event;
  }
}
