







import { Component, Vue } from "vue-property-decorator";

@Component
export default class EventDetailBackLink extends Vue {
  get name() {
    return this.$route.query.backTo || "home";
  }
}
