




import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router/types/router";

import { Meta } from "@/component-decorators";
import EventAddContainer from "@/modules/event/add/EventAddContainer.vue";
import { choicesStore, eventsStore } from "@/store";

const loadData = async (to: Route, from: Route, next: NavigationGuardNext) => {
  await Promise.all([
    eventsStore.loadEvent({ id: to.params.id }),
    choicesStore.loadCountries(),
    choicesStore.loadCities(),
    choicesStore.loadStyles(),
  ]);
  await next();
};

@Component({
  components: { EventAddContainer },
  beforeRouteEnter: loadData,
  beforeRouteUpdate: loadData,
})
export default class EventEditView extends Vue {
  @Meta
  info() {
    return {
      title: this.$t("events.form.editTitle"),
    };
  }
}
