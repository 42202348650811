




import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router/types/router";

import EventDetailContainer from "@/modules/event/detail/EventDetailContainer.vue";
import { eventsStore } from "@/store";

const loadEvent = (to: Route, from: Route, next: NavigationGuardNext) => {
  eventsStore.loadEvent({ id: to.params.id }).then(() => next());
};

@Component({
  components: { EventDetailContainer },
  beforeRouteEnter: loadEvent,
  beforeRouteUpdate: loadEvent,
})
export default class EventDetailView extends Vue {}
