











import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Event } from "@/api/structs";
import AppFailure from "@/components/AppFailure.vue";
import EventDetailItemContent from "@/modules/event/detail/components/EventDetailItemContent.vue";
import EventDetailItemImage from "@/modules/event/detail/components/EventDetailItemImage.vue";

@Component({
  components: { AppFailure, EventDetailItemContent, EventDetailItemImage },
})
export default class EventDetailItem extends Vue {
  @Prop({ required: true }) readonly event!: Event;
  @Prop({ required: true }) readonly apiLoadingEventGoing!: ApiLoading;
}
