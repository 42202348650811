<template>
  <div class="has-text-centered">
    <h1>{{ message }}</h1>
  </div>
</template>

<script>
export default {
  name: "AppFailure",
  props: {
    message: String,
  },
};
</script>
