














import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Event } from "@/api/structs";
import AppFailure from "@/components/AppFailure.vue";
import EventDetailBackLink from "@/modules/event/detail/components/EventDetailBackLink.vue";
import EventDetailItem from "@/modules/event/detail/components/EventDetailItem.vue";

@Component({
  components: {
    AppFailure,
    EventDetailItem,
    EventDetailBackLink,
  },
})
export default class EventDetail extends Vue {
  @Prop({ required: true }) readonly event!: Event;
  @Prop({ required: true }) readonly apiLoadingEvent!: ApiLoading;
  @Prop({ required: true }) readonly apiLoadingEventGoing!: ApiLoading;
}
