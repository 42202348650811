





import { Component, Prop, Vue } from "vue-property-decorator";

import { Event } from "@/api/structs";

@Component
export default class EventDetailItemImage extends Vue {
  @Prop({ required: true }) readonly event!: Event;
}
