




















































import DOMPurify from "dompurify";
import marked from "marked";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Event } from "@/api/structs";
import { getCategoryColor } from "@/colors";
import { getHorizontalRange, MONTH_FORMAT_LONG } from "@/datetimes";
import { translateCategory } from "@/i18n/keys-dynamic";
import EventGoingUsersSamples from "@/modules/event/common/components/EventGoingUsersSamples.vue";
import { GOING_CLICKED, GoingClickedPayload } from "@/modules/event/event-handlers";
import { usersStore } from "@/store";

@Component({
  components: { EventGoingUsersSamples },
})
export default class EventDetailItemContent extends Vue {
  @Prop({ required: true }) readonly event!: Event;
  @Prop({ required: true }) readonly apiLoadingEventGoing!: ApiLoading;

  get bullets() {
    return [
      {
        icon: ["far", "clock"],
        name: this.$t("events.when"),
        text: getHorizontalRange(
          this.event.startsAt,
          this.event.endsAt,
          this.$t.bind(this),
          MONTH_FORMAT_LONG,
        ),
      },
      {
        icon: ["fa", "map-marker-alt"],
        name: this.$t("events.where"),
        text: this.placeWithCity,
      },
      {
        icon: ["fa", "user"],
        name: this.$t("events.organizer"),
        text: `${this.event.organizer}`,
      },
      {
        icon: ["fas", "chart-pie"],
        name: this.$t("events.category"),
        text: translateCategory(this.$t.bind(this), this.event.category),
      },
      {
        icon: ["fas", "walking"],
        name: this.$t("events.styles"),
        text: `${this.event.styles.join(", ")}`,
      },
    ].filter(el => el.text);
  }

  get backTo() {
    return this.$route.query.backTo;
  }

  get isOwner() {
    return this.event.createdBy === usersStore.user?.id;
  }

  get categoryColorClass() {
    return `has-text-${getCategoryColor(this.event.category)}`;
  }

  get placeWithCity() {
    if (this.event.place) {
      return `${this.event.place}, ${this.event.city}`;
    }

    return this.event.city;
  }

  get linkHostname() {
    return new URL(this.event.link).hostname;
  }

  get renderedDescription() {
    return DOMPurify.sanitize(marked(this.event.description));
  }

  get defaultFullName() {
    return this.$t("users.defaultFullName");
  }

  get goingType() {
    return this.event.going ? "is-white" : "is-cocoa-transparent";
  }

  get goingIcon() {
    return this.event.going ? "check" : "plus";
  }

  get goingClass() {
    return this.event.going ? "" : "has-border";
  }

  emitGoingClicked() {
    this.$emit(GOING_CLICKED.name, {
      id: this.event.id,
      going: !this.event.going,
    } as GoingClickedPayload);
  }
}
